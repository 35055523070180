<template>
  <section class="text-center mx-4 pb-2">
    <h1 class="text-blue fs-1">Uh oh! Page Not Found!</h1>
    <div class="row m-auto justify-content-center">
      <div class="col-md col-lg-5">
        <div>
          <img src="@/assets/images/get-involved.jpg" class="img-fluid" alt="Get Involved">
          <p class="fst-italic">Photo by Genevieve Fridley</p>
        </div>
      </div>
      <div class="col-md col-lg-5 d-flex flex-column justify-content-center">
        <p><router-link to="/">Go back to the home page</router-link>.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>